<template>
<b-row>
  <b-col cols="12" xl="6" class="nopadding">
    <div class="col">
      <div class="row">
        <div class="col bg-info">
          <div class="row mt-1">
            <div class="col-md-7">
              <strong># {{$t('common.new_invoice')}}: [{{today}}]</strong>
            </div>
            <div class="col-md-5">
              <strong class="pull-right"><i class="fa fa-money 2x" aria-hidden="true">
                </i> {{formatPrice(total_recal)}} {{$t('common.money_icon')}}</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col bg-secondary">
          <div class="row">
            <div class="col-md-3 form-fixer">
              <b-form-group>
                <label for="ddl_supply">{{$t('common.supply')}}</label>
                <div v-if="$v.product.supply_id.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.product.supply_id.required">{{$t('common.supply_required')}}</div>
                </div>
                <b-form-select v-model="product.supply_id" size="sm" id="ddl_import_supply"
                @change="onSupplyChange(product.supply_id)">
                  <option value="null" disabled>{{$t('common.select_supply')}}</option>
                  <option v-for="g in ls_supply_ddl" :value="g.id" :key="g.id">{{ g.name }}</option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-5 form-fixer">
              <b-form-group>
                <label for="txt_name">{{$t('common.invoice')}}</label>
                <div v-if="$v.product.name.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.product.name.required">{{$t('common.invoice_required')}}</div>
                    <div class="text-danger" v-else-if="!$v.product.name.isUnique">{{$t('common.invoice_existing')}}</div>
                </div>
                <b-form-input type="text" id="txt_name" :placeholder="$t('common.placeholder_invoice')"
                v-model="product.name" maxlength="50" ref="txt_name" size="sm"
                v-on:input="$v.product.name.$touch"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-2 form-fixer">
              <b-form-group>
                <label for="txt_code">{{$t('common.code')}}</label>
                <div v-if="$v.product.code.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.product.code.required">{{$t('common.code_required')}}</div>
                    <div class="text-danger" v-else-if="!$v.product.code.isUnique">{{$t('common.code_existing')}}</div>
                </div>
                <b-form-input type="text" id="txt_code" :placeholder="$t('common.placeholder_code')"
                v-model="product.code" maxlength="25" size="sm"
                v-on:input="$v.product.code.$touch"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-2 form-fixer">
              <b-form-group class="">
                <label for="txt_discount">{{$t('common.discount')}}</label>
                 <money v-model.trim="product.discount" v-bind="money_discount" class="form-control bg-info"
                  id="'txt_discount" size="sm"
                  maxLength="5"></money>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 form-fixer">
              <b-form-group>
                <label for="txt_shipper_name">{{$t('common.delivery')}}</label>
                <div v-if="$v.product.shipper_name.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.product.shipper_name.required">{{$t('common.delivery_required')}}</div>
                </div>
                <b-form-input type="text" id="txt_shipper_name" :placeholder="$t('common.placeholder_delivery')"
                v-model="product.shipper_name" maxlength="50" size="sm"
                v-on:input="$v.product.shipper_name.$touch"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 form-fixer">
              <b-form-group>
                <label for="txt_shipper_phone">{{$t('common.phone')}}</label>
                <div v-if="$v.product.shipper_phone.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.product.shipper_phone.numeric">{{$t('common.phone_number_only')}}</div>
                    <div class="text-danger" v-else-if="!$v.product.shipper_phone.minLength">{{$t('common.phone_ten_number')}}</div>
                </div>
                <b-form-input type="text" id="txt_shipper_phone" :placeholder="$t('common.placeholder_phone')"
                v-model="product.shipper_phone" maxlength="15" size="sm"
                v-on:input="$v.product.shipper_phone.$touch"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-3 form-fixer">
              <b-form-group>
                <label for="ddl_shipper_gender">{{$t('common.gender')}}</label>
                <b-form-select v-model="product.gender" size="sm" id="ddl_shipper_gender">
                  <option value="null" disabled>{{$t('common.select_gender')}}</option>
                  <option v-for="g in ls_gender" :value="g.id" :key="g.id">{{ g.name[$i18n.locale] }}</option>
                </b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-fixer">
              <b-form-group>
                <label for="txt_store_name">{{$t('common.store')}}</label>
                <b-form-input type="text" id="txt_store_name" size="sm"
                 :placeholder="$t('common.placeholder_store')"
                v-model="product.store_name" maxlength="50"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 form-fixer">
              <b-form-group>
                <label for="txt_store_address">{{$t('common.address')}}</label>
                <b-form-input type="text" id="txt_store_address" size="sm"
                :placeholder="$t('common.placeholder_address')"
                v-model="product.store_address" maxlength="200"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-5 form-fixer">
              <b-form-group>
                <label for="'txt_des">{{$t('common.description')}}</label>
                <b-form-input class="form-control" maxlength="300" id="txt_des" size="sm"
                v-model.trim="product.des" :placeholder="$t('common.placeholder_description')"></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <div class="row div-scroll">
        <div class="col">
          <div class="row">
            <fieldset class="scheduler-border col-md-12">
              <legend class="scheduler-border">{{$t('common.product_list')}}</legend>
              <div class="row" v-for="(itm, k) in $v.product.import_details.$each.$iter"
              :key="k" :class="['', (itm.status.$model>-1?(k%2==0?'bg-light':''):'bg-secondary')]">
                <div class="col">
                  <div class="row">
                    <div class="form-group col-md-4 form-fixer-list">
                      <label :for="'ddl_product_'+k">{{$t('common.product')}}</label>
                      <div v-if="$v.product.import_details.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.product_id.required">{{$t('common.product_required')}}</div>
                        <div class="text-danger" v-else-if="!itm.product_id.isUnique">{{$t('common.product_existing')}}</div>
                      </div>
                      <b-form-select :id="'ddl_product_'+k" v-model.trim="itm.product_id.$model"
                      :disabled="product.supply_id==null" size="sm"
                      @change="onProductChange(k,itm.$model)">
                        <option value="null" disabled>{{$t('common.select_product')}}</option>
                        <option v-for="d in ls_product_ddl" :value="d.id"
                            v-bind:key="d.id">{{d.name}} - [{{d.supply&&d.supply.name||''}}]</option>
                      </b-form-select>
                    </div>
                    <div class="form-group col-md-4 form-fixer-list">
                      <label :for="'ddl_unit_'+k">{{$t('common.unit')}}</label>
                      <div v-if="itm.product_id.$model&&$v.product.import_details.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.unit_id.required">{{$t('common.unit_required')}}</div>
                      </div>
                      <b-form-select :id="'ddl_unit_'+k" class="form-control" v-model.trim="itm.unit_id.$model"
                      :disabled="itm.product_id.$model==null" size="sm" @change="onUnitChange(k,itm.unit_id.$model)">
                        <option value="null" disabled>{{$t('common.select_product')}}</option>
                        <option v-for="d in product.import_details[k].ls_unit_ddl" :value="d.id"
                            v-bind:key="d.id">{{JSON.parse(d.name)[$i18n.locale]}}</option>
                      </b-form-select>
                    </div>
                    <div class="form-group col-md-4 form-fixer-list">
                      <label :for="'txt_price_'+k">{{$t('common.price')}}</label>
                      <div v-if="$v.product.import_details.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.price.required">{{$t('common.price_required')}}</div>
                      </div>
                      <money v-model.trim="itm.price.$model" v-bind="money" class="form-control" :disabled="itm.unit_id.$model==null"
                      :name="'txt_price_'+k" :id="'txt_price_'+k" @change.native="onPriceChange(k,itm.price.$model)"
                      maxLength="15"></money>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-2 form-fixer-list">
                      <label :for="'txt_quantity_'+k">{{$t('common.quantity')}}</label>
                      <div v-if="product.supply_id&&itm.product_id.$model&&itm.unit_id.$model&&$v.product.import_details.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.quantity.required">{{$t('common.quantity_required')}}</div>
                        <div class="text-danger" v-else-if="!itm.quantity.numeric">{{$t('common.quantity_number_only')}}</div>
                        <div class="text-danger" v-else-if="!itm.quantity.maxValue">{{$t('common.quantity_max')}}</div>
                      </div>
                      <b-form-input type="number" @change="onQuantityChange(k,itm.quantity.$model)"
                        v-model.trim="itm.quantity.$model" class="form-control"
                        :name="'txt_quantity_'+k" :id="'txt_quantity_'+k" size="sm"
                        maxLength="10" :disabled="product.supply_id==null|| itm.product_id.$model==null||
                        itm.unit_id.$model==null || (itm.import_detail_cars.$model.length>0 &&
                        itm.import_detail_cars.$model.find(x=>x.product_id==itm.product_id.$model)!=null)">
                      </b-form-input>
                    </div>
                    <div class="form-group col-md-2 form-fixer-list">
                      <label :for="'txt_total_'+k">{{$t('common.total')}}</label>
                      <div v-if="$v.product.import_details.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.total.required">{{$t('common.quantity')}}</div>
                        <div class="text-danger" v-else-if="!itm.total.numeric">{{$t('common.quantity_number_only')}}</div>
                      </div>
                      <money v-model.trim="itm.total.$model" v-bind="money"  class="form-control"
                      :name="'txt_total_'+k" :id="'txt_total_'+k" :disabled="product.supply_id==null|| itm.product_id.$model==null||
                        itm.unit_id.$model==null"
                      maxLength="15"></money>
                    </div>
                    <div class="form-group col-md-1 form-fixer-list">
                      <label :for="'chk_is_change_'+k">{{$t('common.change_unit')}}</label>
                      <b-form-checkbox switch size="lg" :disabled="product.supply_id==null"
                      :name="'chk_is_change_'+k" :id="'chk_is_change_'+k"
                      v-model.trim="itm.is_change.$model"></b-form-checkbox>
                    </div>
                    <div class="form-group col-md-2 form-fixer-list" v-if="itm.is_change.$model">
                      <label :for="'ddl_unit1_'+k">{{$t('common.unit')}}</label>
                      <div v-if="$v.product.import_details.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.unit_id1.required">{{$t('common.unit_required')}}</div>
                      </div>
                      <b-form-select :id="'ddl_unit1_'+k" v-model="itm.unit_id1.$model"
                      :disabled="product.supply_id==null" size="sm">
                        <option value="null" disabled>{{$t('common.select_unit')}}</option>
                        <option v-for="d in product.import_details[k].ls_unit_ddl" :value="d.id"
                            v-bind:key="d.id">{{JSON.parse(d.name)[$i18n.locale]}}</option>
                      </b-form-select>
                    </div>
                    <div class="form-group col-md-2 form-fixer-list" v-if="itm.is_change.$model">
                      <label :for="'txt_quantity1_'+k">{{$t('common.quantity')}}</label>
                      <div v-if="$v.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.quantity1.required">{{$t('common.quantity_required')}}</div>
                        <div class="text-danger" v-else-if="!itm.quantity1.numeric">{{$t('common.quantity_number_only')}}</div>
                      </div>
                      <b-form-input type="number" :disabled="product.supply_id==null"
                        v-model.trim="itm.quantity1.$model" class="form-control"
                        :name="'txt_quantity1_'+k" :id="'txt_quantity1_'+k"
                        maxLength="10" size="sm">
                      </b-form-input>
                    </div>
                    <div class="col-md-3 pull-right text-white form-inline" v-if="itm.status.$model>-1">
                      <div v-if="product.id==null">
                        <a class="btn btn-danger mr-1 btn-sm" v-if="product.import_details.length>1"
                          @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                        <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                      </div>
                      <div v-else>
                        <a class="btn btn-danger mr-1 btn-sm"
                        @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                        <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                      </div>
                    </div>
                    <div class="col-md-3 pull-right text-white form-inline" v-else>
                      <div>
                        <a class="btn btn-success mr-1 btn-sm" v-if="product.import_details.length>1"
                          @click="itm.status.$model=0"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="row bg-info ml-1" v-if="itm.import_detail_cars.$model&&itm.import_detail_cars.$model.filter(x=>x.status>-1).length>0
                    || itm.import_detail_cars.$model.find(x=>x.product_id==itm.product_id.$model)">
                    <div class="col pull-right">
                      <div class="row" v-for="(car, c) in itm.import_detail_cars.$each.$iter" :key="c">
                        <div class="form-group col-4 form-fixer-list">
                          <label :for="'txt_car_'+c">{{$t('car.car_name')}}</label>
                          <div v-if="$v.$error" class="pull-right">
                            <div class="text-danger" v-if="!car.car.required">{{$t('car.car_required')}}</div>
                          </div>
                          <b-form-input type="text" :id="'txt_car_'+k+c" :placeholder="$t('car.car_placeholder')"
                          v-model="car.car.$model" maxlength="50" size="sm"
                          v-on:input="car.car.$touch"></b-form-input>
                        </div>
                        <div class="form-group col-4 form-fixer-list">
                          <label :for="'txt_car_quantity_'+c">{{$t('common.quantity')}}</label>
                          <div v-if="$v.$error" class="pull-right">
                            <div class="text-danger" v-if="!car.quantity.required">{{$t('common.quantity_required')}}</div>
                            <div class="text-danger" v-else-if="!car.quantity.numeric">{{$t('common.quantity_number_only')}}</div>
                            <div class="text-danger" v-else-if="!car.quantity.maxValue">{{$t('common.quantity_max')}}</div>
                          </div>
                          <b-form-input type="number" @change="onQuantityCarChange(k,itm.$model)"
                            v-model.trim="car.quantity.$model" class="form-control" size="sm"
                            :name="'txt_car_quantity_'+c" :id="'txt_car_quantity_'+c"
                            maxLength="10" :input="car.quantity.$touch">
                          </b-form-input>
                        </div>
                        <div class="col-4 pull-right text-white form-inline" v-if="car.status.$model>-1">
                          <div v-if="car.id.$model==null">
                            <a class="btn btn-danger mr-1 btn-sm" v-if="itm.import_detail_cars.$model.length>1"
                              @click="removeCarRow(c,itm.$model)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                            <a class="btn btn-success btn-sm" @click="addCarRow(itm.import_detail_cars.$model,itm.product_id.$model)">
                              <i class="fa fa-plus" aria-hidden="true"></i></a>
                          </div>
                          <div v-else>
                            <a class="btn btn-danger mr-1 btn-sm"
                            @click="removeCarRow(c,itm.$model)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                            <a class="btn btn-success btn-sm" @click="addCarRow(itm.import_detail_cars.$model,itm.product_id.$model)">
                              <i class="fa fa-plus" aria-hidden="true"></i></a>
                          </div>
                        </div>
                        <div class="col-md-4 pull-right text-white form-inline" v-else>
                          <div>
                            <a class="btn btn-success mr-1 btn-sm"
                              @click="refreshCarRow(c,itm.$model)"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="row bg-secondary">
        <div class="col-4 form-fixer">
          <b-form-group>
            <label for="ddl_pay_type">{{$t('common.payment_method')}}</label>
            <b-form-select id="ddl_pay_type" v-model.trim="product.pay_type"
            size="sm">
              <option value="null" disabled>{{$t('common.select_payment_method')}}</option>
              <option v-for="d in ls_payment_ddl" :value="d.id"
                  v-bind:key="d.id">{{d.name[$i18n.locale]}}</option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-3 form-fixer">
          <b-form-group>
            <label for="txt_first_pay">{{$t('common.deposit')}}</label>
            <money v-model.trim="product.first_pay" v-bind="money" class="form-control text-primary"
              id="'txt_first_pay" :disabled="product.pay_type!=1"
              maxLength="15"></money>
          </b-form-group>
        </div>
        <div class="col-5 form-fixer">
          <b-form-group>
            <label for="validation_pay_date">{{$t('common.pay_date')}}</label>
            <date-picker v-model="product.pay_date" :config="options" class="date-picker-height"></date-picker>
            <div v-if="$v.$error">
              <p class="text-danger" v-if="!$v.product.pay_date.required">{{$t('common.date_required')}}</p>
            </div>
          </b-form-group>
        </div>
      </div>
      <div class="row pull-right mt-2">
          <b-button variant="outline-info mr-1" size="sm" @click.prevent="clear()"><span class="icon is-small">
            <i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</span></b-button>
          <b-button variant="outline-success" size="sm" @click.prevent="save_product()"><span class="icon is-small">
            <i class="fa fa-check"></i> {{$t('common.button.save')}} </span></b-button>
      </div>
    </div>
  </b-col>
  <b-col cols="12" xl="6" class="nopadding"> 
    <transition name="slide">
      <b-card>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter()">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter()"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="supply" slot-scope="data">
            <label v-if="data.item.supply">{{data.item.supply.name}}</label>
            <label v-else></label>
          </template>
          <template slot="total" slot-scope="data">
            <label v-if="data.item.total>0">{{formatPrice(data.item.total)}}</label>
            <label v-else>0</label>
          </template>
          <template slot="first_pay" slot-scope="data">
            <label v-if="data.item.first_pay>0">{{formatPrice(data.item.first_pay)}}</label>
            <label v-else>0</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
          </template>

          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="in_bill" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="primary" @click="bill_pdf(data.item.id,80)" v-b-modal.viewModal><i
                  class="fa fa-file-pdf-o"></i> Bill 80</b-button>
              <b-button variant="primary" @click="bill_pdf(data.item.id,5)" v-b-modal.viewModal><i
                  class="fa fa-file-pdf-o"></i> Bill A5</b-button>
            </b-button-group>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)">
                <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
              </b-button>
            </b-button-group>
          </template>
          <template slot="bill" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="primary" @click="bill_pdf(data.item.id,4)" v-b-modal.viewModal><i
                  class="fa fa-file-pdf-o"></i> Bill A4</b-button>
              <b-button variant="primary" @click="bill_pdf(data.item.id,5)" v-b-modal.viewModal><i
                  class="fa fa-file-pdf-o"></i> Bill A5</b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        :type="1"
                        @change="change_page">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
    </transition>
  </b-col>
  <b-modal id="viewModal" :title="$t('common.bill_info')" size="lg" header-bg-variant="info" no-body ok-only>
    <div id="import_product_bill_pdf" style="height:450px;"></div>
  </b-modal>
</b-row>
</template>
<style scoped>
  .div-scroll {
    max-height:1000px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  label{
    margin-bottom: 0rem;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }

  legend.scheduler-border {
      font-size: 1.2em !important;
      font-weight: bold !important;
      text-align: left !important;
      width:auto;
      padding:0 10px;
      border-bottom:none;
  }
  .div-scroll {
    max-height:500px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
</style>
<script>
  import pdfobj from 'pdfobject'
  import { validationMixin } from 'vuelidate'
  import { required,requiredIf, minLength,maxLength,numeric } from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'
  import datePicker from 'vue-bootstrap-datetimepicker'
  import moment from 'moment'
  import Pager from '../Pager'
  import { ModelSelect } from 'vue-search-select'
  import Multiselect from 'vue-multiselect'
  import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
  import { Role } from '@/helpers/role.js'
  import { uuid } from 'vue-uuid'
  const greaterThanZero = (value) => value > 0
  const greaterThanMinusOne = (value) => value > -1

  export default {
    components: { datePicker,ModelSelect,Pager,Multiselect },
    mixins: [validationMixin],
    props: {
      hover: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        ls_gender: [],
        today:moment().format("DD-MM-YYYY HH:mm:ss"),
        processing:false,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '$ ',
          precision: 0,
          masked: false
        },
        money_discount: {
          decimal: ',',
          thousands: '.',
          prefix: '% ',
          precision: 0,
          masked: false
        },
        product: {
          id:null,
          import_details:[],
          user_create_id:null,
          user_update_id:null,
          total:0,
          company_id:null,
          business:null,
          branch_id:null,
          supply_id:null,
          des:null,
          name:this.$t('common.invoice')+ " "+ this.$t('common.date')+": ["+moment().format("DD-MM-YYYY HH:mm:ss")+"]",
          code:moment().valueOf().toString(),
          shipper_name:null,
          shipper_phone:null,
          gender:null,
          pay_type:1,
          pay_date:moment().format("DD-MM-YYYY HH:mm:ss"),
          first_pay:0,
          second_pay:0,
          third_pay:0,
          discount:0,
          store_address:null,
          store_name:null
        },
        options: {
          format: 'DD-MM-YYYY HH:mm',
          useCurrent: true,
          showClear: true,
          showClose: true
        }
      }
    },
    validations: {
      product: {
        shipper_name:{
          required,
          maxLength:maxLength(50)
        },
        shipper_phone:{
          numeric,
          maxLength:maxLength(15),
          minLength:minLength(10)
        },
        supply_id:{
          required
        },
        pay_date:{
          required
        },
        name:{
          required,
          async isUnique(value) {
            if (value==null) return true
            if(this.product && this.product.id){return true}

            const res = await this.checkNameUnique({company_id:this.user_login.company_id,name:value})
            return Boolean(res.data)
          }
        },
        code:{
          required,
          async isUnique(value) {
            if (value==null) return true
            if(this.product && this.product.id){return true}

            const res = await this.checkCodeUnique({company_id:this.user_login.company_id,code:value})
            return Boolean(res.data)
          }
        },
        import_details: {
          $each: {
            import_detail_cars:{
              $each:{
                id:{},
                car:{
                  required: requiredIf(function (car) {
                    debugger
                    return car&&car.show_car_list
                  })
                },
                quantity:{
                  required: requiredIf(function (car) {
                    return car&&car.show_car_list
                  }),
                  maxValue: greaterThanZero,
                  numeric
                },
                import_detail_id:{},
                status:{},
                product_id:{}
              }
            },
            product_id:{
              required: requiredIf(function () {
                return this.product.import_details.length>0 && this.product.supply_id
              }),
              async isUnique(value,itm) {
                if(this.product.import_details.length<=0) return Boolean(true)
                let arr=this.product.import_details.filter(x => x.product_id == itm.product_id
                && x.supply_id==itm.supply_id&&x.unit_id==itm.unit_id&&x.is_change==itm.is_change)
                return Boolean(arr.length<=1)
              }
            },
            unit_id:{
              required: requiredIf(function () {
                return this.product.import_details.length>0 && this.product.supply_id
              })
            },
            quantity:{
              maxValue: greaterThanZero,
              required: requiredIf(function () {
                return this.product.import_details.length>0
              }),
              numeric
            },
            is_change:{},
            unit_id1:{
              required: requiredIf(function (itm) {
                return this.product.import_details.length>0 && itm.is_change
              })
            },
            quantity1:{
              maxValue: greaterThanMinusOne,
              required: requiredIf(function (itm) {
                return this.product.import_details.length>0 && itm.is_change
              }),
              numeric
            },
            price:{
              required: requiredIf(function () {
                return this.product.import_details.length>0
              }),
              numeric
            },
            total:{
              required: requiredIf(function () {
                return this.product.import_details.length>0
              }),
              numeric
            },
            status:{},
            ls_unit_ddl:[]
          }
        }
      }
    },
    computed: {
      ...mapGetters('st_import', ['ls_import','per_page','filter','page_count','page']),
      fields(){
        let fields=[]
        let user=this.$store.state.st_authentication.user
        if(user.role==Role.Super_Admin){
          fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
          fields.push({key: 'branch',label:this.$t('common.branch'),sortable: true})
          fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
        }
        fields.push({key: 'supply',label:this.$t('common.supply'),sortable: true})
        fields.push({key: 'name',label:this.$t('common.invoice'),sortable: true})

        fields.push({key: 'total',label:this.$t('common.total'),sortable: true, variant:'primary'})
        fields.push({key: 'first_pay',label:this.$t('common.deposit'),sortable: true, variant:'info'})

        fields.push({key: 'discount',label:this.$t('common.discount') + " %",sortable: true, variant:'danger'})

        if(user.role==Role.Super_Admin || user.role==Role.Admin || user.role==Role.Branch_Admin){
          fields.push({key: 'shipper_name',label:this.$t('common.delivery'),sortable: true})
        }

        fields.push({key: 'pay_date',label:this.$t('common.pay_date'),sortable: true})
        fields.push({key: 'bill',label:this.$t('common.bill'),sortable: false})

        if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
          fields.push({key: 'action',label:'',sortable: false})
        }

        return fields
      },
      total_recal(){
        debugger
        let temp=_.sumBy(this.product.import_details.filter(x=>x.status>=0),"total")
        if(this.product.discount>0){
          temp=temp-(temp*(parseInt(this.product.discount)/100))
        }
        return parseInt(temp)
      },
      ls_product_ddl:{
        get: function(){
          return this.$store.state.st_product.ls_product_ddl
        },
        set: function(lst){
          this.$store.commit('st_product/set_ls_product_ddl',lst)
        }
      },
      is_super () {
        let user= this.$store.state.st_authentication.user
        return user.role==Role.Super_Admin
      },
      business () {
        let user= this.$store.state.st_authentication.user
        return user.company.business
      },
      user_login () {
        return this.$store.state.st_authentication.user
      },
      filter:{
        get: function(){
            return this.$store.state.st_import.filter
        },
        set: function(text){
            this.$store.commit('st_import/set_filter',text)
        }
      },
      ls_unit_ddl(){
        return this.$store.state.st_unit.ls_unit_ddl
      },
      ls_car_ddl(){
        return this.$store.state.st_car.ls_car_ddl
      },
      ls_supply_ddl(){
        return this.$store.state.st_supply.ls_supply_ddl
      },

      filteredAndSortedData:{
        get: function(){
          let vm=this
          let result = vm.$store.state.st_import.ls_import
          if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
          }

          return result
        }
      }
    },
    methods: {
      ...mapActions('st_import', ['get_ls_import', 'apply_filter','change_page']),
      ...mapActions('st_unit', ['get_ls_unit_ddl']),
      ...mapActions('st_product', ['get_ls_product_ddl','get_ls_product_ddl_async']),
      ...mapActions('st_supply', ['get_ls_supply_ddl']),
      ...mapActions('st_car', ['get_ls_car_ddl']),
      nameWithLang ({ name, id }) {
        if(name==null) return ""
        return JSON.parse(name)[this.$i18n.locale]
      },
      onSupplyChange(supply_id){
        debugger
        if(!supply_id) return
        this.get_ls_product_ddl({company_id:this.user_login.company_id,branch_id:this.user_login.branch_id,supply_id:supply_id,business:this.user_login.company.business})
      },
      rowDeleted(item, type){
        if (!item || type !== 'row') return
        if (item.status === -1) return 'table-danger'
      },
      async checkNameUnique(param){
        return await this.$store.dispatch(`st_import/name_unique`,param)
      },

      async checkCodeUnique(param){
        return await this.$store.dispatch(`st_import/code_unique`,param)
      },

      bill_pdf(id,type){
        debugger
        let app=this
        var loader = app.$loading.show()
        app.$store.dispatch('st_import/bill_pdf',{id,type,business:app.business,lang:app.$i18n.locale}).then((response) => {
          if(response && response.data){
            let blob = new Blob([response.data], { type: 'application/pdf' })
            pdfobj.embed(window.URL.createObjectURL(blob), "#import_product_bill_pdf")
          }

          loader.hide()
        })
      },
      onProductChange(k,itm_detail){
        debugger
        k=parseInt(k)
        let product=this.ls_product_ddl.find(x=>x.id==itm_detail.product_id)
        if(product==null) return

        var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
          return product.product_units.filter(function(anotherOne_el){
            if(anotherOne_el.unit_id == array_el.id){
              array_el.buy_price= anotherOne_el.buy_price
              array_el.sell_price= anotherOne_el.sell_price
            }
            return anotherOne_el.unit_id == array_el.id
          }).length > 0
        })

        if(filteredArray.length<=0){
          filteredArray=this.ls_unit_ddl
        }

        this.product.import_details[k].ls_unit_ddl=filteredArray

        this.product.import_details[k].quantity=0
        this.product.import_details[k].total=0
        this.product.import_details[k].unit_id=null

        if(product.cocon){
          if(itm_detail.import_detail_cars.length>0){
            if(itm_detail.import_detail_cars.find(x=>x.product_id==itm_detail.product_id)){
              for (var i = 0; i < itm_detail.import_detail_cars.length; i++) {
                let element = itm_detail.import_detail_cars[i]
                if(element.id){
                  element.show_car_list=true
                  element.status=0
                }else{
                  itm_detail.import_detail_cars.splice(i,1)
                }
              }
            }else{
              itm_detail.import_detail_cars=[]
            }
          }else{
            itm_detail.import_detail_cars.push({id:null,car:null,quantity:0,status:0,company_id:this.user_login.company_id,
              branch_id:this.user_login.branch_id,show_car_list:true,import_detail_id:null,product_id:itm_detail.product_id})
          }
        }else{
          if(itm_detail.import_detail_cars.length>0){
            for (var i = 0; i < itm_detail.import_detail_cars.length; i++) {
              let element = itm_detail.import_detail_cars[i]
              if(element.id){
                element.show_car_list=false
                element.status=-1
              }else{
                itm_detail.import_detail_cars.splice(i,1)
              }
            }
          }
        }
      },
      onUnitChange(k,unit_id){
        if(unit_id==null) return
        debugger
        k=parseInt(k)
        let detail=this.product.import_details[k].ls_unit_ddl.find(x=>x.id==unit_id)
        if(detail){
          this.product.import_details[k].price=detail.buy_price
        }
      },
      onPriceChange(k,event){
        debugger
        if(event==null) return
        debugger
        k=parseInt(k)
        if(this.product.import_details[k].quantity>0){
          this.product.import_details[k].total=parseInt(this.product.import_details[k].quantity) * parseInt(this.product.import_details[k].price)
        }
      },
      onQuantityChange(k,event){
        if(event==null) return
        debugger
        k=parseInt(k)
        if(this.product.import_details[k].quantity>0){
          this.product.import_details[k].total=parseInt(this.product.import_details[k].quantity) * parseInt(this.product.import_details[k].price)
        }else{
          this.product.import_details[k].total=0
        }
      },
      addNewRow() {
        this.product.import_details.push({
          import_detail_cars:[],
          id:null,
          product_id:null,
          price:0,
          total:0,
          unit_id:null,
          quantity:0,
          ls_unit_ddl:[],
          is_change:false,
          unit_id1:null,
          quantity1:0,
          status:0,
          company_id:this.user_login.company_id,
          branch_id:this.user_login.branch_id,
          business:this.user_login.company.business
        })
      },
      onQuantityCarChange(k,item){
        if(k<0) return
        debugger
        let int_k=parseInt(k)
        let sum=0
        let lst=item.import_detail_cars.filter(x=>x.status>=0)
        for(var i=0; i<lst.length;i++){
          sum+= parseInt(lst[i]["quantity"])
        }
        item.quantity=sum
        item.total=sum * parseInt(item.price)
      },
      addCarRow(car_list,product_id) {
        if(!car_list) return
        debugger
        let temp=this.ls_product_ddl.find(x=>x.id==product_id)
        car_list.push({
          id:null,
          show_car_list:temp.cocon,
          car:null,
          product_id:temp.id,
          quantity:0,
          status:0,
          import_detail_id:null,
          company_id:this.user_login.company_id,
          branch_id:this.user_login.branch_id
        })
      },
      refreshCarRow(c,item){
        if(c<0) return
        debugger
        let int_c=parseInt(c)
        let sum=0
        item.import_detail_cars[c].status=0
        let lst=item.import_detail_cars.filter(x=>x.status>=0)
        for(var i=0; i<lst.length;i++){
          sum+= parseInt(lst[i]["quantity"])
        }
        item.quantity=sum
        item.total=sum * parseInt(item.price)
      },
      removeCarRow(c,item){
        if(c<0) return
        debugger
        let sum=0
        let temp=item.import_detail_cars[c]
        if(temp.id==null){
          item.import_detail_cars.splice(c, 1)
        }else{
          item.import_detail_cars[c].status=-1
        }

        let lst=item.import_detail_cars.filter(x=>x.status>=0)
        for(var i=0; i<lst.length;i++){
          sum+= parseInt(lst[i]["quantity"])
        }
        item.quantity=sum
        item.total=sum * parseInt(item.price)
      },
      removeRow(k){
        if(k==null || k<0) return
        debugger
        let temp=this.product.import_details[k]
        if(temp.id==null){
          this.product.import_details.splice(k, 1)
        }else{
          this.confirmDeleteDetail(k)
        }
      },
      getRowCount (items) {
        return items.length
      },

      async rowClicked (item) {
        if(item.status<0) return
        let products = await this.get_ls_product_ddl_async({company_id:this.user_login.company_id,branch_id:this.user_login.branch_id,supply_id:item.supply_id,business:this.user_login.company.business})
        if(products&&products.data){
          debugger
          this.product={...item}
          this.ls_product_ddl=products
          for (var i = 0; i < this.product.import_details.length; i++) {
            let element = this.product.import_details[i]
            let temp=this.ls_product_ddl.find(x=>x.id==element.product_id)
            if(temp){
              var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
                return temp.product_units.filter(function(anotherOne_el){
                  if(anotherOne_el.unit_id == array_el.id){
                    array_el.buy_price= anotherOne_el.buy_price
                    array_el.sell_price= anotherOne_el.sell_price
                  }
                  return anotherOne_el.unit_id == array_el.id
                }).length > 0
              })

              if(filteredArray.length<=0){
                filteredArray=this.ls_product_ddl
              }

              element.ls_unit_ddl=filteredArray
            }
          }
        }
      },

      clear() {
        this.product={id:null,branch_id:null,import_details:[],des:null,type:1,
        name:this.$t('common.bill')+" "+ this.$t('common.date') +": ["+moment().format("DD-MM-YYYY HH:mm:ss")+"]",discount:0,
        store_address:null,supply_id:null,
        store_name:null, pay_date:moment().format("DD-MM-YYYY HH:mm"),
        pay_type:1,first_pay:0,second_pay:0,third_pay:0,gender:null,
        user_create_id:null,user_update_id:null,business:null,code:moment().valueOf().toString(),total:0}
        this.addNewRow()
        this.$v.$reset()
      },
      _validations () {
        return new Promise(resolve => {
          if (this.$v.$error || !this.$v.$pending) {
            return resolve()
          }
          let poll = setInterval(() => {
          if (!this.$v.$pending) {
              clearInterval(poll)
              resolve()
          }
          }, 200)
        })
      },

      async isValid () {
        this.$v.$reset()
        this.$v.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.$error)
      },

      async save_product() {
        var app = this
        const isValid = await this.isValid()
        debugger
        if (!isValid) return
        if (app.processing === true) return

        app.processing = true

        if(app.product.id){
          app.product.user_update_id=app.user_login.id
          debugger
          app.product.total=app.total_recal

          app.$store.dispatch('st_import/update',app.product)
          .then(function (resp) {
              app.get_ls_import()
              app.clear()
              app.$toastr.success(app.$t('common.updated_success'),app.$t("common.message"))
              app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
              app.processing = false
          })
        }else{
          app.product.user_create_id=app.user_login.id
          app.product.business=app.user_login.company.business
          app.product.company_id=app.user_login.company_id
          app.product.branch_id=app.user_login.branch_id
          debugger
          app.product.total=app.total_recal

          app.$store.dispatch('st_import/create',app.product)
          .then(function (resp) {
              app.get_ls_import()
              app.clear()
              app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
              app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
              app.processing = false
          })
        }
      },

      async recoverObj(obj){
        let app=this
        if(obj.status>=0) return
        let co=await app.$store.dispatch('st_import/recover_obj',obj.id)
        if(co && co.data.ok){
          app.get_ls_import()
          app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },
      confirmDeleteDetail(k) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
        .then(value => {
          if(value!=true) return
          this.product.import_details[k].status=-1
        })
        .catch(err => {
          this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
        })
      },

      confirmDelete(obj) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
        .then(value => {
          if(value!=true) return
          this.deleteObj(obj)
        })
        .catch(err => {
          this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
        })
      },
      async deleteObj(obj) {
        let app=this
        if(obj.status<0) return
        if(obj.status==1) {
          app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.product")}),app.$t("common.message"))
          return
        }
        let co=await app.$store.dispatch('st_import/delete_obj',obj.id)
        if(co&&co.data.ok){
          app.get_ls_import()
          app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },

      searchLike:function(item){
        debugger
        return item.created_at.includes(this.filter)
        || item.updated_at&&item.updated_at.includes(this.filter)
        || item.company.name.includes(this.filter)
        || item.branch&&item.branch.name.includes(this.filter)
        || item.total&&item.total.toString().includes(this.filter)
        || item.des&&item.des.includes(this.filter)
        || item.name&&item.name.includes(this.filter)
        || item.code&&item.code.includes(this.filter)
      }
    },
    mounted () {
      let vm =this
      vm.ls_gender=vm.option_gender
      vm.addNewRow()
      vm.get_ls_import()
      vm.get_ls_unit_ddl({company_id:vm.user_login.company_id,business:vm.user_login.company.business,lang:vm.$i18n.locale})
      vm.get_ls_car_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id})
      vm.get_ls_supply_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id})
    }
  }
</script>
